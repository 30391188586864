import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import dynamic from 'next/dynamic';
import Cards from "components/molecules/Card";
import { useEffect, useState } from "react";
import formatNodeImages from "../../lib/formatNodeImages";
import Image from "next/image";
import { useMediaQuery } from "react-responsive";
import { Skeleton } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as Icons from '@fortawesome/free-solid-svg-icons';


const DCustomIcon = dynamic(() => import('../atoms/CustomIcon'),{
  loading: () => (
    <Skeleton
      animation="wave"
      variant="rectangular"
      width={48}
      height={48}
    />
  ),
  ssr: false
});

export default function Slider({ items }) {
  const responsive = {
    allDevices: {
      breakpoint: { max: 3000, min: 300 },
      items: 1,
    },
  };
  return (
    <div className="grid">
      <Carousel
        swipeable={true}
        draggable={true}
        showDots={false}
        arrows={true}
        responsive={responsive}
        infinite={true}
        autoPlay={false}
        autoPlaySpeed={3500}
        keyBoardControl={true}
      >
        {items?.map((item, key) => (
          <div
            key={key}
            className="bg-gray-light w-full h-56 md:h-72 lg:h-[400px]"
          >
            {item?.uri ? (
              <Image src={item?.uri} alt={item?.alt} sizes="100vw" />
            ) : (
              <div>No image</div>
            )}
          </div>
        ))}
      </Carousel>
    </div>
  );
}

export function CatalogoSlider({ elementos }) {
  const responsive = {
    allDevices: {
      breakpoint: { max: 3000, min: 300 },
      items: 1,
    },
  };

  console.log(elementos)

  //Custom arrows for the slider
  const CustomButtonGroup = ({ next, previous }) => {
    return (
      <div className="absolute flex items-center gap-3 right-0 top-2 md:top-6">
        {/*<span className='text-right text-black text-lg md:text-2xl'>INMUEBLES AGREGADOS</span> Uncomment if we need title*/}
        <button
          className="w-10 h-10 rounded bg-gray hover:bg-red"
          onClick={() => previous()}
          aria-label={"previous-button"}
        >
          <DCustomIcon iconName="chevronLeft" viewBox="0 0 50 50" sx={{ color: "white",
            fontSize: "3rem",
          }}/>
          <span className="hidden">previous</span>
        </button>
        <button
          className="w-10 h-10 rounded bg-gray hover:bg-red"
          onClick={() => next()}
          aria-label={"next-button"}
        >
          <DCustomIcon iconName="chevronRight" viewBox="0 0 50 50" sx={{ color: "white",
            fontSize: "3rem",
          }}/>
          <span className="hidden">next</span>
        </button>
      </div>
    );
  };

  //Custom dots for the slider
  const CustomDots = ({ onMove, index, onClick, active }) => {
    return (
      <li className={active ? "active" : "inactive"} onClick={() => onClick()}>
        <button className="w-4 h-4 mx-1.5" aria-label={"dot-button"}>
          <div
            className={`w-4 h-4 ${
              active ? "bg-red" : "bg-gray"
            } rounded-full mx-1.5`}
          />
          <span className="hidden">dots</span>
        </button>
      </li>
    );
  };

  return (
    <div
      className={
        "relative z-50 h-[30rem] lg:h-[35rem] 2xl:h-[50rem] w-full mb-3 md:mb-5"
      }
    >
      <div className="relative left-1/2 -translate-x-1/2 h-full w-4/5">
        <Carousel
          swipeable={true}
          draggable={true}
          showDots={true}
          arrows={false}
          customButtonGroup={<CustomButtonGroup />}
          customDot={<CustomDots />}
          responsive={responsive}
          infinite={true}
          autoPlay={false}
          autoPlaySpeed={3000}
          containerClass="carousel-container"
        >
          {elementos.map((elemento) => {
            //Arrow functions de elementos
            return (
              <div key={elemento.id}>
                <a
                  className="block"
                  href={elemento.field_link_slider?.uri}
                  target="_blank"
                  rel="noreferrer"
                >
                  <div
                    className="relative m-3 md:m-6 h-[20rem] lg:h-[24rem] 2xl:h-[40rem] rounded-md bg-cover bg-no-repeat bg-center bg-gray-dark bg-opacity-50 bg-blend-overlay"
                    style={{
                      backgroundImage: `url(${elemento.field_imagen?.field_media_image?.links?.desktop.href})`,
                    }}
                  />
                </a>
              </div>
            );
          })}
        </Carousel>
      </div>
    </div>
  );
}

export function ViewSlider({ inmuebles, classname }) {
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1440 },
      items: 4,
    },
    laptop: {
      breakpoint: { max: 1440, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 560 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 560, min: 0 },
      items: 1,
    },
  };

  //Custom arrows for the slider
  const CustomButtonGroup = ({ next, previous }) => {
    return (
      <div className="absolute flex items-center gap-3 right-0 top-10 md:top-2">
        <span className="text-right font-bold text-green md:text-white text-lg md:text-2xl">
          ÚLTIMOS INMUEBLES AGREGADOS
        </span>
        <button
          className="w-12 h-12 rounded bg-gray hover:bg-red"
          onClick={() => previous()}
          aria-label={"previous-button"}
        >
          <span className="hidden">Previous</span>
          <DCustomIcon iconName="chevronLeft" viewBox="0 0 50 50" sx={{ color: "white",
            fontSize: "3rem",
          }}/>
        </button>
        <button
          className="w-12 h-12 rounded bg-gray hover:bg-red"
          onClick={() => next()}
          aria-label={"next-button"}
        >
          <span className="hidden">Next</span>
          <DCustomIcon iconName="chevronRight" viewBox="0 0 49 50" sx={{ color: "white",
            fontSize: "3rem",
          }}/>
        </button>
      </div>
    );
  };

  //Custom dots for the slider
  const CustomDots = ({ onMove, index, onClick, active }) => {
    return (
      <li className={active ? "active" : "inactive"} onClick={() => onClick()}>
        <button className="w-6 h-6 mx-1.5" aria-label={"dot-button"}>
          <div
            className={`w-6 h-6 ${
              active ? "bg-red" : "bg-gray"
            } rounded-full mx-1.5`}
          />
          <span className="hidden">dots</span>
        </button>
      </li>
    );
  };

  const [top, setTop] = useState(false);

  useEffect(() => {
    const banner = document.querySelector(".paragraph--slider");
    if (banner.className.includes("paragraph--banner")) {
      setTop(true);
    } else {
      setTop(false);
    }
  }, []);

  const offSetTop = top && "-mt-28 md:-mt-8 lg:-mt-16";

  return (
    <div
      className={
        "relative z-50 h-[38rem] md:h-[34rem] w-full mb-3 md:mb-5  " +
        classname +
        " " +
        offSetTop
      }
    >
      <div className="relative left-1/2 -translate-x-1/2 h-full w-[92%]">
        <Carousel
          swipeable={true}
          draggable={true}
          showDots={true}
          arrows={false}
          infinite={false}
          customButtonGroup={<CustomButtonGroup />}
          customDot={<CustomDots />}
          responsive={responsive}
          autoPlay={false}
          autoPlaySpeed={3000}
          containerClass="carousel-container"
          ssr={true}
          deviceType='desktop'
        >
          {inmuebles?.map((inmueble, key) => (
            <div key={key} className="p-5">
              <Cards
                title={inmueble.title}
                image={formatNodeImages(
                  inmueble,
                  "field_link_imagen",
                  "field_imagenes",
                  "420"
                )}
                alt={inmueble.title}
                price={
                  "$" +
                  inmueble.field_price.toLocaleString("es-MX") +
                  " " +
                  inmueble.field_currency_iso
                }
                treatment={inmueble.field_treatement_type_id.name}
                restroom={inmueble.field_toilet_number}
                bedroom={inmueble.field_bedrooms}
                parking={inmueble.field_parkingspaces}
                size={inmueble.field_area_construction}
                unit={inmueble.field_unit_construction}
                property={inmueble.field_property_type_id.name}
                path={inmueble.path?.alias}
                sx={{ "&.MuiCard-root": { boxShadow: "none" } }}
              />
            </div>
          ))}
        </Carousel>
      </div>
    </div>
  );
}

export function ArticleSlider(props) {
  const mobile = {
    mobile: {
      breakpoint: { max: 767, min: 0 },
      items: 1,
    },
  };
  return (
    <div>
      <Carousel
        swipeable={true}
        draggable={true}
        showDots={true}
        arrows={false}
        responsive={mobile}
        infinite={true}
        autoPlay={true}
        autoPlaySpeed={3000}
        keyBoardControl={true}
        containerClass="carousel-container"
        itemClass="mb-10 mt-1"
      >
        {props.children}
      </Carousel>
    </div>
  );
}


export function LandingSlider(elementos) {
  const items = Object.values(elementos.elementos).flatMap((arr) => arr);

  //Imagenes responsive
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 1023 });
  const isDesktop = useMediaQuery({ minWidth: 1024, maxWidth: 1280 });
  const isDesktopXl = useMediaQuery({ minWidth: 1281, maxWidth: 1440 });
  const isDesktop2xl = useMediaQuery({ minWidth: 1440 });

  const [currentIndex, setCurrentIndex] = useState(0);

  let image =
    items[currentIndex].field_imagen?.field_media_image?.links?.mobile.href;

  if (isMobile) {
    image =
      items[currentIndex].field_imagen?.field_media_image?.links?.mobile.href;
  } else if (isTablet) {
    image =
      items[currentIndex].field_imagen?.field_media_image?.links?.tablet.href;
  } else if (isDesktop) {
    image =
      items[currentIndex].field_imagen?.field_media_image?.links?.desktop.href;
  } else if (isDesktopXl) {
    image =
      items[currentIndex].field_imagen?.field_media_image?.links?.desktop_xl
        .href;
  } else if (isDesktop2xl) {
    image =
      items[currentIndex].field_imagen?.field_media_image?.links?.desktop_2xl
        .href;
  }

  //Funcionalidad botones
  const [srcImage, setSrcImage] = useState(
    image || "/img/no-image-available.svg"
  );

  useEffect(() => {
    setSrcImage(image || "/img/no-image-available.svg");
  }, [currentIndex, items]);

  const goToNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex > 0 ? prevIndex - 1 : items.length - 1
    );
  };

  const goToPrevious = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex < items.length - 1 ? prevIndex + 1 : 0
    );
  };

  return (
    <div className="md:w-[45%] md:mx-auto my-12 flex relative">
      <button
        className="z-[1] absolute top-2/4 transform translate-y-[-50%] p-2.5 bg-transparent border-none text-white text-xl cursor-pointer left-2.5"
        onClick={goToPrevious}
        aria-label="Avanzar a la imagen anterior"
      >
       <DCustomIcon iconName="chevronLeft" viewBox="0 0 45 49" sx={{
            background: "rgba(0, 0, 0, 0.6)",
            borderRadius: "9999px",
            fontSize: "3rem",
          }}/>
      </button>
      <div className="w-full h-auto relative">
        {items[currentIndex].field_link_slider ? (
          <a href={items[currentIndex].field_link_slider?.uri}>
            <Image
              src={srcImage}
              alt={
                items?.field_imagen?.field_media_image?.resourceIdObjMeta.alt
              }
              width={750}
              height={562}
              // placeholder="blur"
              // blurDataURL={srcImage}
              priority={true}
              loading="eager"
              onError={() => setSrcImage("/img/no-image-available.svg")}
              sizes="100vw"
              style={{
                width: "100%",
                height: "auto"
              }} />
          </a>
        ) : (
          <Image
            src={srcImage}
            alt={items?.field_imagen?.field_media_image?.resourceIdObjMeta.alt}
            width={750}
            height={562}
            // placeholder="blur"
            // blurDataURL={srcImage}
            priority={true}
            loading="eager"
            onError={() => setSrcImage("/img/no-image-available.svg")}
            sizes="100vw"
            style={{
              width: "100%",
              height: "auto"
            }} />
        )}
      </div>
      <button
        className="z-[1] absolute top-2/4 transform translate-y-[-50%] p-2.5 bg-transparent border-none text-white text-xl cursor-pointer right-2.5"
        onClick={goToNext}
        aria-label="Avanzar a la siguiente imagen"
      >
        <DCustomIcon iconName="chevronRight" viewBox="0 0 45 49" sx={{
            background: "rgba(0, 0, 0, 0.6)",
            borderRadius: "9999px",
            fontSize: "3rem",
          }}/>
      </button>
    </div>
  );
}

export function SliderCard({ elementos }) {
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1440 },
      items: 4,
    },
    laptop: {
      breakpoint: { max: 1440, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 560 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 560, min: 0 },
      items: 1,
    },
  };

  const isMobile = useMediaQuery({ maxWidth: 767 });
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 1023 });
  const isDesktop = useMediaQuery({ minWidth: 1024, maxWidth: 1280 });
  const isDesktopXl = useMediaQuery({ minWidth: 1281, maxWidth: 1440 });
  const isDesktop2xl = useMediaQuery({ minWidth: 1440 });

  const getImageUrl = (item) => {
    let imgCard;

    if (isMobile) {
      imgCard = item.field_imagen?.field_media_image?.links?.mobile?.href;
    } else if (isTablet) {
      imgCard = item.field_imagen?.field_media_image?.links?.tablet?.href;
    } else if (isDesktop) {
      imgCard = item.field_imagen?.field_media_image?.links?.desktop?.href;
    } else if (isDesktopXl) {
      imgCard = item.field_imagen?.field_media_image?.links?.desktop_xl?.href;
    } else if (isDesktop2xl) {
      imgCard = item.field_imagen?.field_media_image?.links?.desktop_2xl?.href;
    }

    return imgCard || "/img/no-image-available.svg";
  };

  //Custom arrows for the slider
  const CustomButtonGroup = ({ next, previous }) => {
    return (
      <div className="absolute flex items-center gap-3 right-0 top-10 md:top-2">
        <button
          className="w-12 h-12 rounded bg-gray hover:bg-red"
          onClick={() => previous()}
          aria-label={"previous-button"}
        >
          <span className="hidden">Previous</span>
          <DCustomIcon iconName="chevronLeft" viewBox="0 0 50 50" sx={{ color: "white",
            fontSize: "3rem",
          }}/>
        </button>
        <button
          className="w-12 h-12 rounded bg-gray hover:bg-red"
          onClick={() => next()}
          aria-label={"next-button"}
        >
          <span className="hidden">Next</span>
          {/* <DArrowForwardIcon className="text-white" /> */}
          <DCustomIcon iconName="chevronRight" viewBox="0 0 45 50" sx={{ color: "white",
            fontSize: "3rem",
          }}/>
        </button>
      </div>
    );
  };

  return (
    <div className={"relative z-50 h-[38rem] md:h-[33rem] w-full mb-3 md:my-5"}>
      <div className="relative left-1/2 -translate-x-1/2 h-full w-[92%]">
        <Carousel
          swipeable={true}
          draggable={true}
          showDots={false}
          arrows={false}
          infinite={true}
          customButtonGroup={<CustomButtonGroup />}
          customDot={false}
          responsive={responsive}
          autoPlay={false}
          autoPlaySpeed={3000}
          containerClass="carousel-container"
        >
          {elementos?.map((item, key) => (
            <div key={key}>
              <div className="relative flex items-center justify-center mx-3">
                <div className="w-full h-64 relative">
                  {item.field_link_slider ? (
                    <a href={item.field_link_slider?.uri}>
                      <Image
                        src={getImageUrl(item)}
                        className="object-cover w-full h-full"
                        alt="Descripción de la imagen"
                        blurDataURL={getImageUrl(item)}
                        priority={true}
                        loading="eager"
                        fill
                         />
                    </a>
                  ) : (
                    <Image
                      src={getImageUrl(item)}
                      className="object-cover w-full h-full"
                      alt="Descripción de la imagen"
                      blurDataURL={getImageUrl(item)}
                      priority={true}
                      loading="eager"
                      fill
                      sizes="100vw" />
                  )}
                </div>
              </div>
              {item.field_title ? (
                <div className="flex justify-center mt-6">
                  <span className="pb-3 text-2xl border-b-4 border-b-solid border-b-red">
                    {item.field_title}
                  </span>
                </div>
              ) : (
                ""
              )}
              {item.field_subtitulo ? (
                <div className="flex justify-center mt-6">
                  <span className="text-base">{item.field_subtitulo}</span>
                </div>
              ) : (
                ""
              )}
            </div>
          ))}
        </Carousel>
      </div>
    </div>
  );
}

export function CardElements({ elementos }) {

  const isMobile = useMediaQuery({ maxWidth: 767 });
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 1023 });
  const isDesktop = useMediaQuery({ minWidth: 1024, maxWidth: 1280 });
  const isDesktopXl = useMediaQuery({ minWidth: 1281, maxWidth: 1440 });
  const isDesktop2xl = useMediaQuery({ minWidth: 1440 });

  const getImageUrl = (item) => {
    let imgCard;

    if (isMobile) {
      imgCard = item.field_imagen ? item.field_imagen?.field_media_image?.links?.mobile?.href : item.field_icono?.field_media_image?.links?.mobile?.href;
    } else if (isTablet) {
      imgCard = item.field_imagen ? item.field_imagen?.field_media_image?.links?.tablet?.href : item.field_icono?.field_media_image?.links?.tablet?.href;
    } else if (isDesktop) {
      imgCard = item.field_imagen ? item.field_imagen?.field_media_image?.links?.desktop?.href : item.field_icono?.field_media_image?.links?.desktop?.href;
    } else if (isDesktopXl) {
      imgCard = item.field_imagen ? item.field_imagen?.field_media_image?.links?.desktop_xl?.href : item.field_icono?.field_media_image?.links?.desktop_xl?.href;
    } else if (isDesktop2xl) {
      imgCard = item.field_imagen ? item.field_imagen?.field_media_image?.links?.desktop_2xl?.href : item.field_icono?.field_media_image?.links?.desktop_2xl?.href;
    }

    return imgCard || "/img/no-image-available.svg";
  }; 

  // Función para obtener el icono
  const getIcon = (iconName) => {
    return Icons[iconName] || Icons['faQuestionCircle']; 
  };


  return (
    <div className=" 2xl:mx-32">
      <div className="grid grid-cols-1 sm:grid-cols-2 md:flex md:flex-wrap">
        {elementos?.map((item, key) => (
          <div className="w-full sm:w-3/6 lg:w-2/6 mb-8 md:mb-0" key={key}>
            <div className="relative flex items-center justify-center mx-3">
              {item.field_icono ? (
                <div className="w-full h-32 relative">
                  <div className="flex items-center justify-center w-full h-full">
                    <FontAwesomeIcon className="text-green w-24" icon={getIcon(item.field_icono)} size="5x" />
                  </div>
                </div>
              ) : item.field_link_slider ? (
                <div className="w-full h-80 relative">
                  <a href={item.field_link_slider?.uri}>
                    <Image
                      src={getImageUrl(item)}
                      className="object-cover w-full h-full"
                      alt={item.field_imagen?.field_media_image?.resourceIdObjMeta.alt}
                      blurDataURL={getImageUrl(item)}
                      priority={true}
                      loading="eager"
                      fill
                    />
                  </a>
                </div>
              ) : (
                <div className="w-full h-80 relative">
                  <Image
                    src={getImageUrl(item)}
                    className="object-cover w-full h-full"
                    alt={item.field_imagen?.field_media_image?.resourceIdObjMeta.alt}
                    blurDataURL={getImageUrl(item)}
                    priority={true}
                    loading="eager"
                    fill
                    sizes="100vw"
                  />
                </div>
              )}
            </div>
            {item.field_title ? (
              <div className="flex justify-center mt-6">
                <span className="pb-3 text-2xl border-b-4 border-b-solid border-b-red font-bold text-center mx-3">
                  {item.field_title}
                </span>
              </div>
            ) : (
              ""
            )}
            {item.field_subtitulo ? (
              <div className="flex justify-center mt-6">
                <span className="text-lg px-[5%] lg:px-[10%] text-center">{item.field_subtitulo}</span>
              </div>
            ) : (
              ""
            )}
          </div>
        ))}
      </div>
    </div>
  )
}
