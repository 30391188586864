import SearchBanner from "components/molecules/SearchBanner/SearchBanner";
import PropTypes from "prop-types";
import Image from "next/image";
import { useMediaQuery } from "react-responsive";
import dynamic from "next/dynamic";
import { CircularProgress } from "@mui/material";
import { useState, useEffect } from 'react';

const ButtonPrimary = dynamic(() => import("components/atoms/Button"), {
  loading: () => (
    <div className="flex justify-center m-3">
      <CircularProgress color="inherit" />
    </div>
  ),
  ssr: false
});
export default function Banner({
  textBanner,
  titulo,
  uriButton,
  titleButton,
  colorTextButton,
  colorButton,
  classname,
  tabletSrc,
  desktopSrc,
  desktopXlSrc,
  desktop2xlSrc,
  imgUri,
  isHome
}) {
  
  const [src, setSrc] = useState(null);
  const [width, setWidth] = useState(1920);
  const [height, setHeight] = useState(1080);

  const home = isHome;
  const containerSize = home
    ? "h-[45rem] md:h-[29rem] xl:h-[26rem]"
    : "md:h-[45rem] overflow-hidden";
  const imageContainer = home
    ? "-top-[27rem] md:-top-[24rem] lg:-top-[20rem] xl:-top-[10rem] w-full"
    : "md:h-auto";
  const imageSize = home
    ? "h-[74rem] md:h-[60rem] lg:h-[57rem] xl:h-[45rem] w-full"
    : "h-full";

  const isTablet = useMediaQuery({ maxWidth: 1023 });
  const isDesktop = useMediaQuery({ minWidth: 1024, maxWidth: 1280 });
  const isDesktopXl = useMediaQuery({ minWidth: 1281, maxWidth: 1440 });
  const isDesktop2xl = useMediaQuery({ minWidth: 1441 });

  useEffect(() => {
    if (isTablet && tabletSrc) {
      setSrc(tabletSrc.href);
      setWidth(1024);
      setHeight(576);
    } else if (isDesktop && desktopSrc) {
      setSrc(desktopSrc.href);
      setWidth(1280);
      setHeight(720);
    } else if (isDesktopXl && desktopXlSrc) {
      setSrc(desktopXlSrc.href);
      setWidth(1920);
      setHeight(1080);
    } else if (isDesktop2xl && desktop2xlSrc) {
      setSrc(desktop2xlSrc.href);
      setWidth(2560);
      setHeight(1440);
    } else if (desktopXlSrc) {
      setSrc(desktopXlSrc.href);
      setWidth(1920);
      setHeight(1080);
    }
  }, [isTablet, isDesktop, isDesktopXl, isDesktop2xl, tabletSrc, desktopSrc, desktopXlSrc, desktop2xlSrc]);

  
  return (
    <div
      className={`relative flex flex-col justify-start sm:justify-center items-center w-full h-full ${containerSize} ${classname}`}
    >
      <div
        style={{ objectFit: isDesktopXl ? "cover" : "contain" }}
        className={`absolute z-10 ${imageContainer} ${imageSize} bg-gray`}
      >
         {src && width && height && (
          <Image
            priority={true}
            src={src}
            alt={imgUri}
            width={width}
            height={height}
            responsive="true"
            className={"brightness-50 object-cover " + imageSize}
          />
        )}
      </div>
      <div className="relative z-50 md:w-3/5 my-5 md:mt-10 p-6 text-center">
        <div className="text-white font-extrabold text-4xl md:text-6xl mb-8" >{titulo}</div>
        <div className="text-white text-2xl md:text-3xl" dangerouslySetInnerHTML={{ __html: textBanner }} />
        {uriButton ? (
          <div className="inline-block w-fit h-12 mt-10">
            <ButtonPrimary
              url={uriButton}
              textButton={titleButton}
              backgroundButton={colorButton}
              colorTextButton={colorTextButton}
            />
          </div>
        ) : (
          ""
        )}
      </div>
      {home && <SearchBanner />}
    </div>
  );
}

export function BannerTitle({ titleBanner, backgroundImage, imgHeight }) {
  const height = imgHeight || "h-96";
  return (
    <div
      className={`${height} bg-cover bg-no-repeat bg-center bg-gray-dark bg-opacity-50 bg-blend-overlay flex justify-center`}
      style={{ backgroundImage: `url(${backgroundImage})` }}
    >
      <div
        className={`${
          height ? "" : "mt-9"
        } flex items-center p-6 justify-center text-center`}
      >
        {imgHeight ? (
          <h3 className="text-2xl text-white font-bold">{titleBanner}</h3>
        ) : (
          <h1 className="text-3xl md:text-6xl text-white font-bold">
            {titleBanner}
          </h1>
        )}
      </div>
    </div>
  );
}

Banner.propTypes = {
  mobileSrc: PropTypes.object,
  tabletSrc: PropTypes.object,
  desktopSrc: PropTypes.object.isRequired,
  desktopXlSrc: PropTypes.object,
  desktop2xlSrc: PropTypes.object,
  textBanner: PropTypes.string,
  uriButton: PropTypes.string,
  titleButton: PropTypes.string,
  colorButton: PropTypes.string,
  colorTextButton: PropTypes.string,
  titleBanner: PropTypes.string,
};
